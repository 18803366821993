import React, { useState, useEffect, useContext } from 'react';
import AdminLayout from '../../components/admin-layout';
import { Link, Outlet } from 'react-router-dom';
import OverlayBlock from './OverlayBlock';
import { IoWallet } from 'react-icons/io5';
import { LuSettings2 } from 'react-icons/lu';
import { FiClock } from 'react-icons/fi';
import { RiMessage2Line } from 'react-icons/ri';
import { LuHistory } from 'react-icons/lu';
import { HiOutlineUserCircle } from 'react-icons/hi2';
import { IoWalletOutline } from 'react-icons/io5';
import { IoMdHelpCircleOutline } from 'react-icons/io';
import { RiDeleteBinLine } from 'react-icons/ri';
import { LuLogOut } from 'react-icons/lu';
import { FiChevronRight } from 'react-icons/fi';
import { logoutOnJwtExpire } from '../../utils/helperFunctions';
import { HiOutlineArrowSmallLeft } from 'react-icons/hi2';
import { getUserApi } from '../../apis';
import { AppContext, ProfileSliderContext } from '../../appContext';
import { IoMdLock } from 'react-icons/io';
import { MdNotificationAdd } from 'react-icons/md';
import { FaChartPie } from 'react-icons/fa6';
import { HiUsers } from 'react-icons/hi2';
import ConsultationViewRequest from '../../components/ConsultationCard/ConsultationViewRequest';
function ProfileLayout() {
  const { loggedInUser } = useContext(AppContext);
  const [slider, setSlider] = useState(false);
  const handelSlider = () => {
    setSlider(!slider);
  };
  const URL = window.location;
  // hide mobile navigation for particular route
  var activeLink = '';
  if (
    URL.pathname === '/profile/details' ||
    URL.pathname === '/profile/appointment' ||
    URL.pathname === '/profile/details' ||
    URL.pathname === '/profile/settings' ||
    URL.pathname === '/profile/help' ||
    URL.pathname === '/profile/deleteaccount' ||
    URL.pathname === '/profile/logout'
  ) {
    activeLink = 'bg-blue-200';
  }
  const settingsLinks = [
    {
      linkTo: '/profile/details',
      icon: (
        <HiOutlineUserCircle className="text-2xl text-blue-500 text-inherit" />
      ),
      mobIcon: <RiMessage2Line className="text-2xl text-blue-500 m-auto" />,
      name: 'Personal Details',
    },
    {
      linkTo: '/profile/appointment',
      icon: <LuHistory className="text-2xl text-blue-500 text-inherit" />,
      mobIcon: <FiClock className="text-2xl text-blue-500 m-auto" />,
      name: 'Appointment History',
    },
    // {
    //   linkTo: "/profile/payment",
    //   icon: <IoWalletOutline className="text-2xl text-blue-500 text-inherit" />,
    //   mobIcon: (
    //     <IoWallet className="text-2xl text-blue-500 text-coalBlack m-auto" />
    //   ),
    //   name: "Payment Method",
    // },
    {
      linkTo: '/profile/changepassword',
      icon: <IoMdLock className="text-2xl text-blue-500 text-inherit" />,
      mobIcon: '',
      name: 'change password',
    },
    {
      linkTo: '/profile/aboutus',
      icon: <HiUsers className="text-2xl text-blue-500 text-inherit" />,
      mobIcon: '',
      name: 'About Us',
    },
    {
      linkTo: '/profile/privacy',
      icon: <FaChartPie className="text-2xl text-blue-500 text-inherit" />,
      mobIcon: '',
      name: 'Privacy and Safety',
    },
    {
      linkTo: '/profile/help',
      icon: (
        <IoMdHelpCircleOutline className="text-2xl text-blue-500 text-inherit" />
      ),
      mobIcon: '',
      name: 'Help Center',
    },
    {
      linkTo: '/profile/deleteaccount',
      icon: <RiDeleteBinLine className="text-2xl text-blue-500 text-inherit" />,
      mobIcon: '',
      name: 'Delete Account',
    },
    {
      linkTo: '/profile/logout',
      icon: <LuLogOut className="text-2xl text-blue-500 text-inherit" />,
      mobIcon: '',
      name: 'Logout',
      onclick: logoutOnJwtExpire,
    },
  ];
  let settingsLinkBlock;
  settingsLinkBlock = settingsLinks?.map((data, i) => {
    return (
      <Link
      to={data.linkTo}
      className={`block min-h-11 p-1 rounded-full ${
        data.linkTo === URL.pathname ? 'bg-sky-200' : ''
      } ${data.linkTo === '/profile/privacy' ? 'pointer-events-none' : ''}`}
      key={i}
    >
        <div className="flex items-center">
          <div className="inline-flex gap-4 items-center">
            <div className="size-9 rounded-full bg-blue-200 inline-flex justify-center items-center">
              {data.icon}
            </div>
            <p className="text-coalBlack capitalize baseText">{data.name}</p>
          </div>
          <div className="size-9 rounded-full ms-auto inline-flex justify-center items-center">
            <FiChevronRight className="text-lg text-coalBlack" />
          </div>
        </div>
      </Link>
    );
  });
  let settingsLinkBlockSmallDevice;
  settingsLinkBlockSmallDevice = settingsLinks?.map((data, i) => {
    return (
      <Link
  to={data.linkTo}
  className={`block min-h-11 p-1 rounded-full ${
    data.linkTo === '/profile/privacy' ? 'pointer-events-none' : ''
  }`}
  key={i}
  onClick={handelSlider}
>

        <div className="flex items-center">
          <div className="inline-flex gap-4 items-center">
            <div className="size-9 rounded-full bg-blue-200 inline-flex justify-center items-center">
              {data.icon}
            </div>
            <p className="text-coalBlack baseText capitalize">{data.name}</p>
          </div>
          <div className="size-9 rounded-full ms-auto inline-flex justify-center items-center">
            <FiChevronRight className="text-lg text-coalBlack" />
          </div>
        </div>
      </Link>
    );
  });
  const [ViewRequestBlock, setViewRequestBlock] = useState(false);
  const [currentrequest, setcurrentrequest] = useState(null);
  const [profileData, setprofileData] = useState(null);
  function handelViewRequestBlock(data, proData) {
    setViewRequestBlock(!ViewRequestBlock);
    console.log('consultdata', data?._id);
    setcurrentrequest(data?._id);
    setprofileData(proData);
  }
  return (
    <>
      <ProfileSliderContext.Provider
        value={[slider, setSlider, handelViewRequestBlock]}
      >
        <section>
          <AdminLayout>
            <main className="lg:p-6">
              <section className="mx-auto grid  grid-cols-1 bg-[#FAFAFA] rounded-3xl min-h-screen  relative overflow-x-hidden p-6 lg:container lg:grid-cols-3 ">
                <div className="lg:p-10 ">
                  <div className="flex items-center gap-2">
                    <div className="size-9 rounded-full active:bg-blue-50 inline-flex justify-center items-center lg:hidden">
                      <Link to={'/'}>
                        <HiOutlineArrowSmallLeft className="text-coalBlack text-lg" />
                      </Link>
                    </div>
                    <h1 className="headingText text-coalBlack">Profile</h1>
                  </div>
                  <div className="flex flex-col gap-4 justify-center items-center max-w-xs ms-0 py-6">
                    <div className="size-16 bg-gray-100 rounded-full">
                      {loggedInUser?.data?.hospitalProfile?.profilePic ? (
                        <img
                          src={loggedInUser?.data?.hospitalProfile?.profilePic}
                          className="size-16 rounded-full object-cover"
                        />
                      ) : (
                        <div className="size-16  rounded-full bg-avatar"></div>
                      )}
                    </div>
                    <h2 className="text-coalBlack baseText !font-medium text-center max-w-[70%] capitalize">
                      {loggedInUser?.data?.userName}
                    </h2>
                  </div>

                  {/* mobile devices links  */}
                  <div className="space-y-3 block lg:hidden">
                    {settingsLinkBlockSmallDevice}
                  </div>
                  {/* desktop devices links  */}
                  <div className="space-y-3 hidden lg:block">
                    {settingsLinkBlock}
                  </div>
                </div>
                <OverlayBlock>
                  <Outlet />
                </OverlayBlock>
              </section>
            </main>
            {ViewRequestBlock && (
              <ConsultationViewRequest
                closeFunction={handelViewRequestBlock}
                currentdata={currentrequest}
                profileData={profileData}
              />
            )}
          </AdminLayout>
        </section>
      </ProfileSliderContext.Provider>
    </>
  );
}

export default ProfileLayout;
