import React, { useCallback, useContext, useEffect, useState } from 'react';
import { BiSolidDollarCircle } from 'react-icons/bi';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { useFormik } from 'formik';
import SimpleButton from '../common/buttons/SimpleButton/SimpleButton';
import { EditProfileLoader, Loader } from '../common/loader';
import { InputErrorMessage } from '../errorMessages';
import { FiChevronLeft } from 'react-icons/fi';
import toast from 'react-hot-toast';
import { createSubscription, getSubscriptionPlan } from '../../apis';
import * as yup from 'yup';
import { AppContext } from '../../appContext';
import useRazorpay from 'react-razorpay';
import Moment from 'react-moment';

function RenewSubscription({ renewStatus,closeFunction,setplancreate }) {
  console.log(renewStatus);
  const { loggedInUser } = useContext(AppContext);
  const [loader,setLoader]=useState(false);
  const [plan, setPlan] = useState([]);
  const [btnLoader, setBtnLoader] = useState(false);
  let activeBg = 'bg-[#438883]/[10%]';
  let activeText = 'text-[#438883]';
  useEffect(() => {
    getPlan();
  }, [loggedInUser]);

  const Renewsubscriptioncall=async() => {
      try {
        setBtnLoader(true)
        const payload = {
          plan_id: renewStatus?.planId?.razarPayPlanId,
          hospitalId: loggedInUser?.data?.hospitalProfile?._id,
        };
        console.log(payload);
        const res = await createSubscription(payload);
        if (res.status === 200) {
          console.log(res?.data?.result);
          console.log(res?.data?.result?.razarPaySubscriptionId);
          handlePayment(res?.data?.result);
        } else {
          toast.error("Failed to Renew subscription. Please try again.");
        }
      } catch (error) {
        console.error("Error Renew subscription:", error);
        toast.error(`${error?.response?.data?.message || "Unknown error"}`);
      } finally {
        setBtnLoader(false);
      }
    }
  const [Razorpay] = useRazorpay();
  const handlePayment = useCallback(
    (order) => {
      const order_id = order.razarPaySubscriptionId;
      // Ensure order.id is defined
      if (!order_id) {
        console.error('Order ID is required for payment.');
        return;
      }
      const amountInPaise = order.amount * 100; // Convert amount to paise (smallest currency unit)
      const options = {
        key: process.env.REACT_APP_RAZARPAY_ID, // Your Razorpay key
        amount: amountInPaise.toString(), // Amount in paise
        currency: 'INR',
        name: 'MODO',
        subscription_id: order_id,
        handler: (response) => {
          setBtnLoader(false);
          console.log('Payment Success', response);
          const payload = {
            hospitalId: order.hospitalid,
            subscription_id: order_id,
          };
          closeFunction();
          toast.success('Subscription Renewed successfully');
          location.reload();
        },
        prefill: {
          name: order.name,
          contact: order.phoneno,
        },
        theme: {
          color: '#1648CE',
        },
        modal: {
          ondismiss: () => {
            console.log('Payment Failed or Closed');
            toast.error('Payment Cancelled');
            setBtnLoader(false);
          },
        },
      };

      const rzpay = new Razorpay(options);
      rzpay.open();
    },
    [Razorpay]
  );
  const ChooseDifferentPlan=()=>{
    setplancreate(true)
  }

  const subscriptionTabs = (
    <div className="block ">
      <section className="space-y-4">
        <p className="subHeadingText mb-2">Renew Subscription</p>
            <label
              className={`p-4 rounded-2xl border border-gray-200 relative max-w-sm mx-auto flex items-start min-h-28 gap-4 cursor-pointer ${
               activeBg} `}>
              <div
                className={`inline-flex justify-center items-center size-16 min-w-16 rounded-full ${
                  'bg-white'}`}>
                <div className="size-9 rounded-full">
                  {renewStatus?.planId?.planIcon != '' ? (
                    <img
                      src={renewStatus?.planId?.planIcon}
                      className="size-9 rounded-full object-cover"
                    />
                  ) : null}
                </div>
              </div>
              <div className={ activeText}>
                <p className="subHeadingText  capitalize">{renewStatus?.planId?.planName}</p>

                <p className="baseText  capitalize">{renewStatus?.planId?.planDescription}</p>
                <div className="flex items-center mt-1">
                  <p className="baseText !font-medium capitalize">
                    {renewStatus?.planId?.period} ₹{renewStatus?.planId?.planAmount}
                  </p>
                </div>
              </div>
              <div className={`absolute top-3 right-3 ${'visible'}`}>
                <IoIosCheckmarkCircle className="text-2xl text-[#438883]" />
              </div>
            </label>
      </section>
      <div className="my-10 mb-20 max-w-sm mx-auto ">
         <SimpleButton
          title={btnLoader ? <EditProfileLoader /> : 'Renew Subscription'}
          onClickEvent={Renewsubscriptioncall}
          buttonType={'primary'}
          customClass={'w-full rounded-2xl '}
        />
         <SimpleButton
         title={'Choose Different Plan'}
         onClickEvent={ChooseDifferentPlan}
          buttonType={'primary'}
          customClass={'w-full rounded-2xl mt-3 '}
        />
       
      </div>
    </div>
  );
  const getPlan = async () => {
    const res = await getSubscriptionPlan();
    if (res.status == 200) {
      console.log(res?.data?.result);
      setPlan(res?.data?.result);
      setLoader(false);
    }
  };

  return (
    <div>
      <div>{subscriptionTabs}</div>
    </div>
  );
}

export default RenewSubscription;
