
import { API_BASE_URL } from './baseurl';
import ProfileDefault from '../../src/Assets/img/covers/userDefault.png';
import moment from 'moment';

const currDate = moment().format('YYYY-MM-DD');

export const getToken = () => localStorage.getItem('token');
export const setToken = (token) => localStorage.setItem('token', token);
export const removeToken = () => localStorage.removeItem('token');
export const isAuth = () => Boolean(getToken());
export const logoutOnJwtExpire = () => localStorage.clear();
export const preventBack = () => window.history.forward();
export const assignBucketUrl = (path) => {
  return encodeURI(path?.startsWith('uploads/') ? API_BASE_URL + '/' + path : path);
};
export const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    margin: '0 !important',
    minHeight: '0'
  }),
};

export const autoHeightText = (el) => {
  el.target.style.height = '69px';
  el.target.style.height = 1 + el.target.scrollHeight + 'px';
};

Date.prototype.addHours = function (h) {
  this.setTime(this.getTime() + h * 60 * 60 * 1000);
  return this;
};

function SplitTime(numberOfHours) {
  let Days = Math.floor(numberOfHours / 24);
  let Remainder = numberOfHours % 24;
  let Hours = Math.ceil(Remainder);
  if(Hours==24){
    Days=Days+1;
    Hours=0;
  } 
  return { Days, Hours};
}
const date1 = new Date('April 10, 2023 17:24:00');

export const capitalizeFirstLetter = (string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  } else {
    return '';
  }
};