import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <main className="flex justify-center items-center h-screen w-full p-4 lg:w-[60%] max-w-screen-2xl">
      <section className=" space-y-8">
        <div className="">
          <div className="border-2 border-gray-300 w-14 h-12 rounded-md relative">
            <div className="border-t-4 border-gray-300 border-dotted mt-1 w-4 ms-1 border-spacing-x-5"></div>
            <p className="border-t-2  border-gray-300 mt-1"></p>
            <div className="size-20 rounded-full bg-gradient-to-b from-blue-400 vai-blue-500 to-blue-600 absolute -top-16 -right-[120px] flex justify-center items-center">
              <p className="text-white baseText lg:!text-2xl font-light">404</p>
              <span className="size-2 rounded-full bg-gradient-to-b from-blue-400 vai-blue-500 to-blue-600  absolute bottom-0 left-0"></span>
            </div>
          </div>
          <div className="w-16 h-1 bg-gradient-to-b from-gray-100 vai-gray-100 to-gray-200 rounded-[100%] mt-1.5 -ms-1"></div>
        </div>
        <p className="uppercase text-4xl text-sky-500 font-normal lg:text-5xl">
          PAGE NOT FOUND
        </p>
        <p className="text-base text-coalBlack font-light space-y-1 lg:text-lg">
          <span className="block">We searched everywhere for this page.</span>
          <span className="block">
            {' '}
            Are you sure the webpage URL is correct?
          </span>{' '}
          <span className="block">Contact the website owner.</span>
        </p>
        <div>
          <a
            href="/"
            className="border border-blue-500 text-blue-500 px-4 py-2 min-h-11 rounded-full hover:text-blue-800 inline-block"
          >
            Go Back Home
          </a>
        </div>
      </section>
    </main>
  );
};

export default NotFound;
