
import { FileEarmark,Check,Download,ExclamationCircleFill,TrashFill } from 'react-bootstrap-icons';
import ProgressBar from 'react-bootstrap/ProgressBar';
import {  FaAddressCard } from 'react-icons/fa';
import './Filecomponent.css';
export const FileUploadComponent = ({ name, filesize, percentage,isfileerror }) => {
  return (
    <div className={isfileerror ? 'mt-2 flex gap-5 items-center justify-between shadow-md rounded-2xl p-5 bg-white  border-danger':'mt-2 flex gap-5 items-center justify-between shadow-md rounded-2xl p-5 bg-white '}>
      <div >
      <FaAddressCard className="text-2xl text-accent" />
      </div>
      <div className='file-name-container-pw'>
        <div className='file-name-pw'>{name}</div>
        <div className='file-size-pw'>{filesize}</div>
        {
          !isfileerror &&   <div className='progress-bar-div-pw'>
            <div className='progress-bar-pw'> 
              <ProgressBar now={percentage}  />
            </div>
            <div>{percentage} %</div>
          </div>
        }
      
      </div>
      {(percentage === 100 && !isfileerror) &&(
        <div className='success-upload'>
          <Check color='#fff' />
        </div>
      )}
      {
        isfileerror && (
          <div className='download-upload'>
            <ExclamationCircleFill color='red' />
          </div>
        )

      }
    </div>
  );
};
export const FileprofileComponent = ({ name, filesize, percentage,isfileerror }) => {
  return (
    <div className={isfileerror ? 'flex gap-5 flex-col items-center justify-between   border-danger':'flex flex-col gap-5 items-center justify-between w-[100%]'}>
     {(percentage === 100 && !isfileerror) && (
  <div className='success-upload absolute  right-4 transform translate-x-1/2 -translate-y-1/2' style={{top:20}}>
    <Check className="text-white" />
  </div>
)}

      <div className='w-[100%] flex flex-row items-center justify-between'>
      <div className='truncate w-[70%]'>{name}</div>
        <div className='file-size-pw'>{filesize}</div>
        </div>
        <div className='w-[100%] flex flex-row items-center justify-between'>
        {
          !isfileerror &&   <div className='w-[100%] flex flex-row items-center justify-between'>
            <div className='w-[80%] h-[5px] mr-1'> 
              <ProgressBar now={percentage}  />
            </div>
            <div>{percentage}%</div>
          </div>
        }
      
      </div>
     
      {
        isfileerror && (
          <div className='download-upload'>
            <ExclamationCircleFill color='red' />
          </div>
        )

      }
    </div>
  );
};
// export const FilePreviewComponent = ({ name, filesize, url,id,Deletefun,index,filedeleteloader,setFileDeleteLoader }) => {
//   return (
//     <div className='mt-2 file-upload bg-[#fff]'>
//       <div className='file-preview'>
//         <FileEarmark color='#00A46F' size={12} />
//       </div>
//       <div className='file-name-container-pw'>
//         <div className='file-name-pw'>{name}</div>
//         <div className='file-size-pw'>{filesize}</div>
//       </div>
//       {url && (
//         <div className='download-upload'>
//           {
//             filedeleteloader ? 
//             <EditProfileLoader /> 
//            : <img src={trashIcon} onClick={() => {
//               Deletefun(id,index)
//              }} className='cursor-pointer' />
//           }
          
//           <a href={url} target='_blank' rel="noreferrer">
//             <Download color='#00A46F' size={14} />
//           </a>
//         </div>
          
        
//       )}
        
      
//     </div>
//   );
// };
