import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { toast } from "react-hot-toast";
import { loginApi, verifyOtp } from "../../apis";
import { AppContext } from "../../appContext";
import { EditProfileLoader } from "../../components/common/loader";

export default function Otp() {
  const [isWrongOtp, setIsWrongOtp] = useState(false);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [repeatTimer, setRepeatTimer] = useState("");
  const [otp, setOTP] = useState("");
  const [timer, setTimer] = useState("00:00");

  const navigate = useNavigate();
  const location = useLocation();
  const appContext = useContext(AppContext);
  const { setIsLoggedIn } = appContext;
  const loginEmail = location.state.email;
  const keepLoggedIn = location.state.keepLoggedIn;
  const Ref = useRef(null);

  const onVerifyOtp = async (values) => {
    values.preventDefault();
    try {
      setLoader(true);
      if (otp.length < 6) {
        setLoader(false);
        setIsWrongOtp(true);
        setErrorMsg("Please enter 6 digits otp");
      } else {
        values.OTP = {
          otp: otp,
          email: loginEmail,
          keepLoggedIn: keepLoggedIn,
        };
        const response = await verifyOtp(values.OTP);
        const data = response.data;
        if (response.status === 200) {
          localStorage.setItem("token", data.token);
          setIsLoggedIn(true);
          navigate("/");
          toast.success(data.message);
        } else {
          setIsWrongOtp(true);
          setErrorMsg("Wrong OTP");
        }
      }
    } catch (error) {
      setLoader(false);
      toast.error(error?.response?.data?.message, { id: "001" });
    }
  };

  const resendOtp = async (values) => {
    try {
      values = { email: loginEmail };
      const res = await loginApi(values);
      if (res.status === 200) {
        setRepeatTimer(values);
        toast.success(res.data.message, { id: "resendSuccess" });
      }
    } catch (error) {
      const message = error.response.data.message || error.response.statusText;
      toast.error(message, { id: "error" });
    }
  };

  const handleOtpInput = (otp) => {
    setIsWrongOtp(false);
    setErrorMsg("");
    setOTP(otp);
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return { total, minutes, seconds };
  };
  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    setTimer("00:59");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 59);
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime());
    setInterval(() => {
      setShow(false);
    }, 60000);
    setTimeout(() => {
      setShow(true);
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repeatTimer]);

  return (
    
    <>
      <div className="min-h-screen  flex flex-col justify-center items-center sign-in-up !h-screen w-full  sign-in-bg">
        <div className="col-lg-3 p-6 overflow-y-auto bg-white sm:h-fit flex flex-col justify-center md:justify-start rounded-xl relative z-20">
        <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="100"
              height="100"
              viewBox="0 0 50 50"
              className="mx-auto"
            >
              <path d="M 17 5 C 14.250484 5 12 7.2504839 12 10 L 12 12 L 10 12 C 7.2504839 12 5 14.250484 5 17 L 5 40 C 5 42.749516 7.2504839 45 10 45 L 33 45 C 35.749516 45 38 42.749516 38 40 L 38 38 L 40 38 C 42.749516 38 45 35.749516 45 33 L 45 10 C 45 7.2504839 42.749516 5 40 5 L 17 5 z M 17 7 L 40 7 C 41.668484 7 43 8.3315161 43 10 L 43 33 C 43 34.668484 41.668484 36 40 36 L 38 36 L 36 36 L 17 36 C 15.331516 36 14 34.668484 14 33 L 14 23 C 14 21.883334 14.883334 21 16 21 L 19 21 L 19 27 C 19 29.197334 20.802666 31 23 31 L 29 31 L 31 31 L 34 31 C 36.197334 31 38 29.197334 38 27 L 38 17 C 38 14.250484 35.749516 12 33 12 L 14 12 L 14 10 C 14 8.3315161 15.331516 7 17 7 z M 10 14 L 12 14 L 14 14 L 33 14 C 34.668484 14 36 15.331516 36 17 L 36 27 C 36 28.116666 35.116666 29 34 29 L 31 29 L 31 23 C 31 20.802666 29.197334 19 27 19 L 21 19 L 19 19 L 16 19 C 13.802666 19 12 20.802666 12 23 L 12 33 C 12 35.749516 14.250484 38 17 38 L 36 38 L 36 40 C 36 41.668484 34.668484 43 33 43 L 10 43 C 8.3315161 43 7 41.668484 7 40 L 7 17 C 7 15.331516 8.3315161 14 10 14 z M 21 21 L 27 21 C 28.116666 21 29 21.883334 29 23 L 29 29 L 23 29 C 21.883334 29 21 28.116666 21 27 L 21 21 z"></path>
            </svg>
          <div className="mt-10">
            <h1 className="ls-tight font-bolder display-5 text-dark mb-5">
              Welcome Back{" "}
            </h1>
            <p className="text-dark h4">We couldn't be happier to have you. </p>
            <div className="col-lg-12 px-2 mt-8">
              <form onSubmit={onVerifyOtp} className="sign-in-up">
                <h2 className="font-bolder h-4 text-dark mb-8">OTP</h2>
                <div className="mb-5">
                  <label className="form-label" htmlFor="email">
                    Email address
                  </label>
                  <input
                    type="email"
                    className="form-control transparent-input-border"
                    id="email"
                    placeholder="Your email address"
                    value={loginEmail}
                    disabled={true}
                  />
                </div>
                <div className="mb-5">
                  <div className=" otpBox ">
                    <OtpInput
                      inputStyle={{
                        marginLeft: "0",
                        width: "2.375rem",
                        height: "2.375rem",
                        borderRadius: "0.313rem",
                        textAlign: "center",
                        border: "1px solid rgba(0,0,0,0.3)",
                      }}
                      className={"me-2 text-center fs-3  w-50 otpInputt"}
                      errorStyle={{ border: "solid 1px red" }}
                      hasErrored={isWrongOtp}
                      isInputNum
                      value={otp}
                      onChange={(otp) => handleOtpInput(otp)}
                      numInputs={6}
                      separator={<span> </span>}
                    />
                  </div>
                  <div className="resend-again">
                    {show ? (
                      <>
                        <div className="resendBtnhide d-inline ">
                          Resend again
                        </div>
                        <span> ({timer})</span>
                      </>
                    ) : (
                      <>
                        <div
                          className="resendBtnShow d-inline cursor-pointer"
                          onClick={() => resendOtp()}
                        >
                          <a className="si-hover-2 text-black">Resend Again</a>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <button className="btn btn_primary_black w-full" type="submit">
                  {!loader ? (
                    "Submit"
                  ) : (
                    <div className="text-xs">
                      <EditProfileLoader />
                    </div>
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
