import {
  fileUpload,
  GET,
  PATCH,
  POST,
  NOTOKENPOST,
  fileUploadwithprogress,
  DeleteApi,
  DELETE,
} from './api-interface';
export const getAppointmentHistory = (id) =>
  GET(`/api/v1/hospital/consultation/AppointmentHistory?hospitalId=${id}`);
// credit purchases start
export const getCreditBasePrice = () => GET('/api/v1/admin/Credit/get');
export const CreditPurchases = (payload) =>
  POST('/api/v1/hospital/Credit/creditPurchase', payload);
export const CreditPurchasesPaymentSuccess = (payload) =>
  POST('/api/v1/hospital/Credit/paymentsuccess', payload);
export const cancelSubscription = (payload) =>
  POST('/api/v1/Subscription/Cancel', payload);

// credit purchases end
export const getSpecialization = () => GET('/api/v1/admin/Specalaization/get');
export const getCreditusedHisoryApi = (id) =>
  GET(`/api/v1/hospital/Credit/CreditusedHistory?hospitalId=${id}`);
export const getcreditpurhcasegraph = (id) =>
  GET(`/api/v1/hospital/Credit/creditPurchasegraph?hospitalId=${id}`);
//Authentication APIs..........................
export const SignupApi = (payload) =>
  NOTOKENPOST('/api/v1/hospital/user/create', payload);
export const ForgotPasswordsendotpApi = (payload) =>
  NOTOKENPOST('/api/v1/hospital/user/forgotPassword', payload);
export const ForgotPasswordVerifyotpApi = (payload) =>
  NOTOKENPOST('/api/v1/hospital/user/verifyOtp', payload);
export const ForgotPasswordChangepasswordApi = (payload) =>
  NOTOKENPOST('/api/v1/hospital/user/changePassword', payload);
export const loginApi = (payload) =>
  NOTOKENPOST('/api/v1/Hospital/user/login', payload);
export const getUserApi = () => GET('/api/v1/hospital/user/byAuth');
export const getDashboardApi = (id) =>
  GET(`/api/v1/dashboard/hospital/Dashboard?hospitalId=${id}`);
export const getProfilebyId = (Id) =>
  GET(`/api/v1/hospital/profile/getprofile?userId=${Id}`);
export const getHospitalProfilebyId = (Id) =>
  GET(`/api/v1/hospital/profile/byId?id=${Id}`);
export const NotifyMeAPi = (payload) =>
  PATCH('/api/v1/hospital/profile/updatenotifyme', payload);
//fikle upload API for brief...
export const fileUploadApi = (payload, onUploadProgress, file) => {
  return fileUploadwithprogress(
    '/api/v1/uploads/licenceAttch',
    payload,
    onUploadProgress,
    file
  );
};
export const createSubscription = (payload) =>
  POST('/api/v1/Subscription/create', payload);

export const getSubscriptionPlan = () => GET('/api/v1/plan/getData');
export const NewregisteratoinApi = (payload) =>
  NOTOKENPOST('/api/v1/hospital/profile/create', payload);
export const getdoctorslistAPI = () =>
  GET('/api/v1/doctor/profile/getalldoctors');
export const getRecentConsltationAPI = (payload) =>
  GET(`/api/v1/dashboard/hospital/recentRequestbyId?hospitalId=${payload}`);
export const insertconsultationAPI = (payload) =>
  POST('/api/v1/hospital/consultation/create', payload);
export const updateconsultationAPI = (payload) =>
  PATCH('/api/v1/hospital/consultation/update', payload);
export const DeleteconsultationAPI = (payload) =>
  DELETE(`/api/v1/hospital/consultation/delete?consultationId=${payload}`);
export const UpdateUserDetails = (payload) =>
  PATCH('/api/v1/hospital/profile/update', payload);
export const confirmPassword = (payload) =>
  POST('/api/v1/hospital/user/confirmPassword', payload);
export const DeleteAccountAPI = (payload) =>
  PATCH('/api/v1/hospital/user/DeleteAccount', payload);
export const ConsultationbyID = (id) =>
  GET(`/api/v1/dashboard/doctor/consultationById?_id=${id}`);
export const ConsultationCompletedAPI = (payload) =>
  PATCH('/api/v1/hospital/consultation/consultationcompleted', payload);
export const ReportNoshowAPI = (payload) =>
  PATCH('/api/v1/hospital/consultation/report-noshow', payload);
export const getNotificationAPI = (id, filter) =>
  GET(`/api/v1/dashboard/hospital/notification?_id=${id}&filter=${filter}`);
export const updateNotificationReadApi = (payload) =>
  PATCH('/api/v1/notification/update', payload);


export const verifyOtp = (payload) =>
  NOTOKENPOST('/api/v1/common/auth/verifyOtp', payload);
export const verifyToken = (payload) =>
  NOTOKENPOST('/api/v1/hospital/user/googlelogin', payload);

export const updateUserApi = (payload) => PATCH('/api/v1/user/update', payload);
export const updateUserByIdApi = (payload) =>
  PATCH('/api/v1/user/updateById', payload);
export const getRecentActivityApi = (payload) =>
  GET('/api/v1/common/recentActivity/admin', payload);

export const FreshdeskApi = (payload) =>
  POST('/api/v1/common/Freshdesk/CreateContacts', payload);



export const fetchRegionApi = () => GET('/json/region/region.json');
export const fetchClientStatusApi = () => GET('/json/clientStatus/status.json');
export const fetchIndustryApi = () => GET('/api/v1/helper/industry-list');
export const fetchCountrycodeApi = () =>
  GET('/json/countryCode/countryCode.json');
export const fetchEngagementTypeApi = () =>
  GET('/json/engagementType/engagementType.json');
export const fetchCategoryApi = () => GET('/json/categories/categories.json');
export const fetchClientApi = () => GET('/json/clientType/clientType.json');
export const fetchClientApiV2 = () =>
  GET('/api/v2/admin/companies/allCompanies');
export const uploadProfileImgApi = (payload) =>
  fileUpload('/api/v1/common/uploads/file', payload);

//fikle upload API for projects...
export const ProjectfileUploadApi = (payload, onUploadProgress, file) => {
  return fileUploadwithprogress(
    '/api/v1/common/uploads/projectattach',
    payload,
    onUploadProgress,
    file
  );
};
export const createBriefAttachApi = (payload) =>
  POST('/api/v2/admin/brief/attachment/add', payload);
export const createProjectAttachApi = (payload) =>
  POST('/api/v2/admin/project/attachment/add', payload);

//attachment Delete

export const DeleteBriefAttachApi = (briefID, fileID) =>
  DeleteApi(
    `/api/v2/admin/brief/attachment/remove?briefId=${briefID}&attachId=${fileID}`
  );
export const DeleteProjectAttachApi = (projectID, fileID) =>
  DeleteApi(
    `/api/v2/admin/project/attachment/remove?projectId=${projectID}&attachId=${fileID}`
  );

export const getDashboardCountApi = () => GET('/api/v1/admin/dashboard/counts');
//other skills json...
export const getOtherSkillsApi = () => GET('/json/skills/skills.json');

export const weavyTokenApi = (payload) => {
  return POST('/api/v2/common/weavy/getToken', payload);
};
export const getOddsWeavyTokenApi = (payload) => {
  return POST('/api/v1/weavy', payload);
};

export const weavyAddProjectApi = (payload) => {
  return POST('/api/v2/common/weavy/addProject', payload);
};

export const isValidWebsiteLink = (search) => {
  return GET(`/api/v1/helper/checkUrl?url=${search}`);
};
export const getAllConsultation = (id, sort, filterType, filterValue) => {
  return GET(
    `/api/v1/hospital/consultation/getAllConsultation?hospitalId=${id}&status=${sort}&${filterType}=${filterValue}`
  );
};
export const getMonthlyDue = (payload) => {
  const { hospitalId, month, year } = payload;
  return GET(
    `/api/v1/hospital/payment/getMothDueRecords?Month=${month}&year=${year}&hospitalId=${hospitalId}`
  );
};
export const getPaymentHistory = (payload) => {
  const { hospitalId, month, year } = payload;
  return GET(
    `/api/v1/hospital/payment/PaymentHistory?Month=${month}&year=${year}&hospitalId=${hospitalId}`
  );
};
export const checkMonthlyDue = (payload) => {
  return GET(
    `/api/v1/hospital/payment/CheckMonthlyDue?hospitalId=${payload}`
  );
};
export const getSpecializationDoctorList = (payload) => {
  return GET(
    `/api/v1/doctor/Consultation/getDoctorList?Specalaization=${payload}`
  );
};
export const PayMonthlyDue = (payload) => {
  return POST('/api/v1/hospital/payment/PayMonthlyDue', payload);
};
