import React, { useState, useEffect, useContext } from 'react';
import DashboardSidenav from '../Sidebar/Sidebar';
import SearchHeader from './SearchHeader';
import {getUserApi } from '../../apis';
import '../oddsChat/component/weavyStyle..css';
import WeavyToastNotification from '../oddsChat/component/WeavyToastNotification';
const AdminLayout = ({ children }) => {
  const urlPathLocation = window.location.pathname;
  const [profileData, setprofileData] = useState('');


  const fetchData = async () => {
    try {
      const res = await getUserApi();
      if (res.status === 200) {
        console.log(res.data.Profile);
        setprofileData(res?.data?.Profile);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="flex ">
      <DashboardSidenav />
      <div className="w-full min-h-screen bg-[#FAFAFE] xl:bg-bodybackground">
        <SearchHeader profileData={profileData} />
        <div className={'content '}>{children}</div>
      </div>
      <WeavyToastNotification/>
     
    </div>
  );
};
export default AdminLayout;
