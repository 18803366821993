import React, { useContext } from 'react';
import { IoNotifications } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { AppContext } from '../../appContext';

function SearchHeader({ profileData }) {
  const { loggedInUser,chatCount } = useContext(AppContext);
  let badgeElm ;
  if(chatCount >0){
    badgeElm =<span className="p-1 inline-flex justify-center items-center min-w-[18px] h-[18px] rounded-full bg-red-500 text-white !font-semibold smallText absolute top-0 right-0">
      {chatCount}
    </span>;
  }else{
    badgeElm =<div className="w-2 h-2 bg-gray-300 absolute top-1.5 right-2 rounded-full"></div>;
  }
  return (
    <div className="items-center py-6 px-6 bg-white hidden lg:flex xl:px-10 sticky top-0 z-[899] border-b border-gray-100">
      <div className="w-fit flex gap-4 items-center xl:gap-6 ms-auto">
        <Link to={'/notification'} className="relative">
          <IoNotifications className="text-2xl text-navLink p-1.5 hover:bg-gray-50 rounded-full w-9 h-9 cursor-pointer" />
          {badgeElm}
        </Link>
        {/* <HiMail className="text-2xl text-gray-400 p-1.5 hover:bg-gray-50 rounded-full w-9 h-9 cursor-pointer" /> */}
        <div className="flex items-center gap-2">
          {loggedInUser?.data?.hospitalProfile?.profilePic ? (
            <img
              className="w-10 h-10  rounded-full object-cover"
              src={loggedInUser?.data?.hospitalProfile?.profilePic}
            />
          ) : (
            <div className="w-10 h-10  rounded-full bg-avatar" />
          )}

          <p className="baseText text-navLink capitalize">
            {loggedInUser?.data?.userName}
          </p>
        </div>
      </div>
    </div>
  );
}

export default SearchHeader;
