import React, { useContext, useEffect, useState,useCallback } from 'react';
import MobileModel from '../Modals/MobileModel';
import { BiRupee } from 'react-icons/bi';
import Select from 'react-select';
import SimpleButton from '../common/buttons/SimpleButton/SimpleButton';
import MainOddsPopup from '../common/oddsPopup/MainOddsPopup';
import { purchasesCredit, purchasesCreditValidation } from '../Validation';
import { useFormik } from 'formik';
import ConfirmPopup from '../confirmPopup/ConfirmPopup';
import toast from 'react-hot-toast';
import { EditProfileLoader, Loader } from '../common/loader';
import {
  CreditPurchases,
  CreditPurchasesPaymentSuccess,
  getCreditBasePrice,
  getUserApi,
} from '../../apis';
import { AppContext } from '../../appContext';
import useRazorpay from 'react-razorpay';
import LogoImage from '../../Assets/odds_logo_blue.png';

function BuyCredits({ closeFunction,getItem }) {
  const [loader, setLoader] = useState(true);
  const [conformPopUp, setConformPopUp] = useState(false);
  const [purchaseCreditDetails, setPurchaseCreditDetails] = useState(null);
  const basePrice = purchaseCreditDetails?.creditBasePrice;
  const [amountToPay, setAmountToPay] = useState(basePrice);
  const [amountDiscount, setamountDiscount] = useState(0);
  const [hospitalDetails, setHospitalDetails] = useState(null);
  const [subscriptionstatus, setsubscriptionstatus]=useState(false);
  const [subscriptionpercentage, setsubscriptionpercentage]=useState(0);
  const [subscriptionName, setsubscriptionName]=useState(0);
  function handelConfirmPopUp() {
    setConformPopUp(!conformPopUp);
  }
 const [loading,setloading]=useState(false);
  const formik = useFormik({
    initialValues: {
      creditItem: '1',
    },
    enableReinitialize: true,
    validationSchema: purchasesCreditValidation,
    onSubmit: async (values) => {
      const { creditItem } = values;
      setloading(true)
      const payload = {
        hospitalId: hospitalDetails?.hospitalProfile?._id,
        hospitalName: hospitalDetails?.hospitalProfile?.hospitalName,
        noOfCredits: creditItem,
        amtPaid: amountToPay, 
        amountDiscount:amountDiscount
              
      };
      console.log(payload);
      toPurchaseCredit(payload);
      
    },
  });
  const message = (
    <p className='baseText'>
      Are you sure you want to purchase {formik.values.creditItem} credits?
    </p>
  );
  function reduceByPercentage(amount, percentage) {
    let reduction = amount * (percentage / 100);
    let reducedAmount = amount - reduction;
    return { reducedAmount: reducedAmount, reduction: reduction };
}
  const handleChange = (e) => {
    e.preventDefault();
    const newValue = parseInt(e.target.value, 10);
    if (isNaN(newValue)) {
      setAmountToPay(0);
    } else {
      console.log(newValue * basePrice)
      var Totalamt=newValue * basePrice;
      console.log(reduceByPercentage(Totalamt,1))
      var reducedamt=reduceByPercentage(Totalamt,0);
      if(subscriptionstatus){
       reducedamt=reduceByPercentage(Totalamt,subscriptionpercentage);
      }
      setAmountToPay(reducedamt.reducedAmount);
      setamountDiscount(reducedamt.reduction);
    }
    formik.handleChange(e);
  };
  const toPurchaseCredit = async (payload) => {
    try {
      const res = await CreditPurchases(payload);
      console.log(res);
      if (res.status === 200) {
        //toast.success(res.data.message || 'Credits purchased successfully');
        const payload = {
          hospitalId: hospitalDetails?.hospitalProfile?._id,
          razarpayOrderId: res.data.Transaction.razarpayOrderId,
        };
        console.log(res.data.paymentinfo);
        handlePayment(res.data.paymentinfo);
        // paymentSuccess(payload);
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 500) {
        toast.error(error.response.statusText);
        closeFunction();
      }
    }
    finally{
      setloading(false);
    }
  };
  const paymentSuccess = async (payload) => {
    try {
      const res = await CreditPurchasesPaymentSuccess(payload);
      if (res.status === 200) {
        closeFunction();
        toast.success(res.data.message || 'Credits purchased successfully');
        getItem();   
      }
    } catch (error) {
      console.log(error);
      closeFunction();
    } finally {
    }
  };
  const toGetCreditBasePrice = async (subscriptionstatusparam,discountPercentage) => {
    try {
      const res = await getCreditBasePrice();
      if (res.status === 200) {
        setPurchaseCreditDetails(res?.data?.credit[0]);
        console.log(subscriptionstatusparam)
        var reducedamt=reduceByPercentage(res?.data?.credit[0]?.creditBasePrice,0);
        if(subscriptionstatusparam){
          console.log(discountPercentage);
         reducedamt=reduceByPercentage(res?.data?.credit[0]?.creditBasePrice,discountPercentage);
        }
        setAmountToPay(reducedamt.reducedAmount);
        setamountDiscount(reducedamt.reduction);
        setLoader(!loader);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(!loader);
    }
  };
  const fetchHospitalProfile = async () => {
    try {
      const res = await getUserApi();
      if (res.status === 200) {
        setHospitalDetails(res.data?.Profile);
        var subscription=false;
        var discountPercentage=0;
        if(res?.data?.Profile?.hospitalProfile?.subscriptionId?.status==="active"){
          subscription=true;
          setsubscriptionstatus(true);
          setsubscriptionpercentage(res?.data?.Profile?.hospitalProfile?.subscriptionId?.planId?.discountPercentage);
          discountPercentage=res?.data?.Profile?.hospitalProfile?.subscriptionId?.planId?.discountPercentage;
          setsubscriptionName(res?.data?.Profile?.hospitalProfile?.subscriptionId?.planId?.planName);
        }
        else{
          setsubscriptionstatus(false);
        }
        toGetCreditBasePrice(subscription,discountPercentage);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
  fetchHospitalProfile();
    
  }, []);
  const [Razorpay] = useRazorpay();
  const handlePayment = useCallback((order) => {
    const order_id=order.orderId;
    // Ensure order.id is defined
    if (!order_id) {
      console.error('Order ID is required for payment.');
      return;
    }
    const amountInPaise = order.amount * 100; // Convert amount to paise (smallest currency unit)
    const options = {
      key:process.env.REACT_APP_RAZARPAY_ID, // Your Razorpay key
      amount: amountInPaise.toString(), // Amount in paise
      currency: 'INR',
      name: 'MODO',
      //description: "Test Transaction",
      image: LogoImage, // Uncomment if you have a logo
      order_id: order_id,
      handler: (response) => {
        console.log('Payment Success', response);
        handelConfirmPopUp();
        const payload = {
          hospitalId: order.hospitalid,
          razarpayOrderId: order_id,
        };
        paymentSuccess(payload);
      },
      prefill: {
        name: order.name,
        // email: "youremail@example.com",
        contact: order.phoneno,
      },
      // notes: {
      //   address: "Razorpay Corporate Office",
      // },
      theme: {
        color: '#1648CE',
      },
      modal: {
        ondismiss: () => {
          handelConfirmPopUp();
          console.log('Payment Failed or Closed');
          toast.error('Payment Cancelled');
          closeFunction();
          getItem();
        }
      }
    };
  
    const rzpay = new Razorpay(options);
    rzpay.open();
  }, [Razorpay]);
  const modelBody = (
    <>
      {loader ? (
        <div className="min-h-60 flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <div className="text-coalBlack">
          <div className="lg:hidden">
            <p className="headingText">Credit Purchase</p>
          </div>
          <div className=" items-center hidden lg:flex">
            <p className="headingText">Credit Purchase</p>
            <p
              className="baseText !font-medium text-navLink ms-auto cursor-pointer"
              onClick={closeFunction}
            >
              Discard
            </p>
          </div>
          <p className="mt-4 subHeadingText uppercase">
            1 x =
            <BiRupee className="text-2xl inline-block" />
            <span className="font-bold">{basePrice || ''}</span>
          </p>
          {
            subscriptionstatus &&
            <p className="mt-2 mb-2 text-sm text-grey-500 text-navLink">
            {subscriptionpercentage}% discount from your {subscriptionName}
            </p>
          }
         
          <div className="my-4">
            <form autoComplete="off">
              <div className="relative">
                <input
                  type="text"
                  name="creditItem"
                  autoComplete="false"
                  className={
                    Boolean(formik.touched.creditItem) &&
                    Boolean(formik.errors.creditItem)
                      ? 'form-control border-danger transparent-input-border'
                      : 'form-control transparent-input-border'
                  }
                  id="creditItem"
                  placeholder="Enter credit :"
                  value={formik.values.creditItem}
                  onChange={handleChange}
                />{' '}
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
  const modelFooter = (
    <>
      {!loader && (
        <div className="mt-10 mb-6">
          <SimpleButton
            title={`Pay ${amountToPay != 0 ? `₹ ${amountToPay}` : ''}`}
            buttonType={'primary'}
            customClass={'w-full rounded-2xl '}
            onClickEvent={handelConfirmPopUp}
          />
        </div>
      )}
    </>
  );

  return (
    <section>
      <div className="lg:hidden">
        <MobileModel
          MobileModelBody={modelBody}
          MobileModelFooter={modelFooter}
          popupCloseFunction={closeFunction}
        />
      </div>
      <div className="lg:block hidden">
        <MainOddsPopup>
          <div className="min-w-96 py-4">
            {modelBody}
            {modelFooter}
          </div>
        </MainOddsPopup>
      </div>
      {conformPopUp && (
        <ConfirmPopup
          message={message}
          popupStatus={conformPopUp}
          handelConfirmPopUp={handelConfirmPopUp}
          onclickFunction={formik.handleSubmit}
          confirmtext={loading ? <EditProfileLoader /> : 'Yes'}
          canceltext={'No'}
        />
      )}
    </section>
  );
}

export default BuyCredits;
