import React, { useEffect, useRef, useState, useContext } from 'react';
import AdminLayout from '../../components/admin-layout';
import UserProfile from '../../components/UserProfile/UserProfile';
import { FiLogOut } from 'react-icons/fi';
import { GoArrowUpRight } from 'react-icons/go';
import SideMessageNotification from './SideMessageNotification';
import Chart from 'chart.js/auto';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ConsultationCard from '../../components/ConsultationCard/ConsultationCard';
import { Link } from 'react-router-dom';
import Createrequest from '../../components/ConsultationCard/Createrequest';
import ViewRequest from '../../components/ConsultationCard/Viewrequest';
import {
  getDashboardApi,
  getNotificationAPI,
  getProfilebyId,
  getRecentConsltationAPI,
  getUserApi,
} from '../../apis';
import { AppContext } from '../../appContext';
import ConsultationViewRequest from '../../components/ConsultationCard/ConsultationViewRequest';
import BookingsCard from './BookingsCard';
import MonthlyCredits from './MonthlyCredits';
import ConsultationHistory from './ConsultationHistory';
import { escape } from 'lodash';
function DashboardOdds() {
  const [profileData, setprofileData] = useState('');
  const [RecentRequest, setRecentRequest] = useState('');
  const { setIsLoggedIn, logout } = useContext(AppContext);
  const [monthlydata, setmonthlydata] = useState([]);
  const [monthlylable, setmonthlylable] = useState([]);
  const [totalcreditused, settotalcreditused] = useState(0);
  const [inpersoncount, setinpersoncount] = useState(0);
  const [virtualcount, setvirtualcount] = useState(0);
  const [consultationhistorylable, setconsultationhistorylable] = useState([]);
  const [consultationhistoryremote, setconsultationhistoryremote] = useState(
    []
  );
  const [
    consultationhistoryinperson,
    setconsultationhistoryinperson,
  ] = useState([]);
  const [consultationhistorysucc, setconsultationhistorysucc] = useState([]);
  const [notificationFilter, setNotificationFilter] = useState('');
  const [
    consultationhistoryremotecount,
    setconsultationhistoryremotecount,
  ] = useState(0);
  const [
    consultationhistoryinpersoncount,
    setconsultationhistoryinpersoncount,
  ] = useState(0);
  const [
    consultationhistorysucccount,
    setconsultationhistorysucccount,
  ] = useState(0);

  const handleFilterChange = (event) => {
    setNotificationFilter(event.target.value);
    // Call getNotification function with the selected filter value
    getNotification(profileData?.hospitalProfile?._id, event.target.value);
  };
  const fetchData = async () => {
    try {
      const res = await getUserApi();
      if (res.status === 200) {
        console.log(res.data.Profile);
        getNotification(res?.data?.Profile?.hospitalProfile?._id);
        Dashboard(res?.data?.Profile?.hospitalProfile?._id);
        setprofileData(res?.data?.Profile);
        getRecentRequest(res?.data?.Profile?.hospitalProfile?._id);

        setIsLoggedIn(res?.data?.Profile);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const Dashboard = async (id) => {
    try {
      const res = await getDashboardApi(id);
      if (res.status === 200) {
        console.log(res.data);
        if (res?.data?.consttypecount?.length > 0) {
          setinpersoncount(res?.data?.consttypecount?.[0]?.inperson);
          setvirtualcount(res?.data?.consttypecount?.[0]?.Virtual);
        }
        if (res?.data?.MonthlyExpenditure?.length > 0) {
          var date_ranges = await res?.data?.MonthlyExpenditure?.map(
            (item) => item.date_range
          );
          console.log(date_ranges);
          setmonthlylable(date_ranges);
          var totalCredits = await res?.data?.MonthlyExpenditure?.map(
            (item) => item.totalfee
          );
          setmonthlydata(totalCredits);
          const totalCreditCount = await res?.data?.MonthlyExpenditure?.reduce(
            (acc, usage) => acc + usage.totalfee,
            0
          );
          settotalcreditused(totalCreditCount);
        }
        if (res?.data?.completedconstdata?.length > 0) {
          const date_ranges = res.data.completedconstdata.map((item) => {
            const totalItem = item.total[0]; // Assuming there's only one item in the total array
            return `${totalItem.monthName}`;
          });
          console.log(date_ranges);
          setconsultationhistorylable(date_ranges);
          // setmonthlylable(date_ranges);

          const inperson = res.data.completedconstdata.map((item) => {
            if (item.inperson.length > 0) {
              const inpersonItem = item.inperson[0]; // Assuming there's only one item in the inperson array
              return inpersonItem.count;
            }
          });
          console.log(inperson);
          setconsultationhistoryinperson(inperson);

          const virtual = res.data.completedconstdata.map((item) => {
            if (item.Virtual.length > 0) {
              const virtualItem = item.Virtual[0]; // Assuming there's only one item in the Virtual array
              return virtualItem.count;
            }
          });
          console.log(virtual);
          setconsultationhistoryremote(virtual);
          const total = res.data.completedconstdata.map((item) => {
            if (item.total.length > 0) {
              const totalItem = item.total[0]; // Assuming there's only one item in the total array
              return totalItem.count;
            }
          });
          console.log(total);
          setconsultationhistorysucc(total);
          const inpersonCountconst = res.data.completedconstdata.reduce(
            (acc, item) => {
              console.log(item.inperson.length);
              if (item.inperson.length > 0) {
                const inpersonItem = item.inperson[0]; // Assuming there's only one item in the inperson array
                return acc + inpersonItem.count;
              }
              return acc;
            },
            0
          );
          console.log(inpersonCountconst, 'inpersoncont....................');
          setconsultationhistoryinpersoncount(inpersonCountconst);
          const virtualCountconst = res.data.completedconstdata.reduce(
            (acc, item) => {
              if (item.Virtual.length > 0) {
                const virtualItem = item.Virtual[0]; // Assuming there's only one item in the Virtual array
                return acc + virtualItem.count;
              }
              return acc;
            },
            0
          );
          console.log(virtualCountconst);
          setconsultationhistoryremotecount(virtualCountconst);
          const totalCount = res.data.completedconstdata.reduce((acc, item) => {
            if (item.total.length > 0) {
              const totalItem = item.total[0]; // Assuming there's only one item in the total array
              return acc + totalItem.count;
            }
            return acc;
          }, 0);
          console.log(totalCount);
          setconsultationhistorysucccount(totalCount);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getRecentRequest = async (userId) => {
    try {
      const res = await getRecentConsltationAPI(userId);
      if (res.status === 200) {
        console.log(res.data.request);
        setRecentRequest(res.data.request);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const [notification, setnotificaiton] = useState(null);
  const getNotification = async (userId, filterval) => {
    console.log(userId);
    if (!filterval) {
      if(notificationFilter){
        filterval=notificationFilter;
      }
      else{
        filterval = 'today';
      }
    }
    try {
      const res = await getNotificationAPI(userId, filterval);
      if (res.status === 200) {
        //console.log(res.data.hospitalNotification);
        setnotificaiton(res.data.hospitalNotification);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const [createRequestBlock, setCreateRequestBlock] = useState(false);
  const [editRequestBlock, setEditRequestBlock] = useState(false);

  const [ViewRequestBlock, setViewRequestBlock] = useState(false);
  function handelViewRequestBlock(data) {
    setViewRequestBlock(!ViewRequestBlock);
    setcurrentrequest(data);
  }

  function handelCreateRequestBlock() {
    setCreateRequestBlock(!createRequestBlock);
  }
  const [currentrequest, setcurrentrequest] = useState(null);
  function handelEditRequestBlock(data) {
    setEditRequestBlock(!editRequestBlock);
    setcurrentrequest(data?._id);
  }
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  let consultationBlock = null; // Declare consultationBlock outside the conditional block

  if (RecentRequest) {
    consultationBlock = RecentRequest?.map((data, i) => (
      <ConsultationCard
        data={data}
        ViewRequest={() => {
          handelViewRequestBlock(data?._id);
        }}
        editRequest={() => {
          handelEditRequestBlock(data);
        }}
        onload={fetchData}
        fillBtnTitle="View Request"
        unFillBtnTitle="Delete"
        fillBtnfun={() => {
          handelViewRequestBlock(data?._id);
        }}
      />
    ));
  }

  return (
    <AdminLayout>
      <div className="p-4 lg:hidden sticky top-0 left-0 bg-white z-50">
        <UserProfile
          NotificationBlock={true}
          mailId={profileData?.email}
          imgUrl={profileData.hospitalProfile?.profilePic}
        />
      </div>
      <div className="mt-2 p-4 lg:hidden ">
        <div className="bg-white p-2 rounded-2xl shadow-blue_dropshadow">
          <div className="flex items-center justify-between bg-blue-50 p-2 rounded-lg">
            <div className="inline-flex gap-2 items-center">
              {profileData?.hospitalProfile?.profilePic ? (
                <img
                  className="size-10  rounded-full object-cover"
                  src={profileData?.hospitalProfile?.profilePic}
                />
              ) : (
                <div className="size-10  rounded-full bg-avatar" />
              )}
              <div className="">
                <p className="text-coalBlack baseText !font-medium capitalize w-full line-clamp-1">
                  {profileData?.hospitalProfile?.hospitalName}
                </p>
                {/* <p className="text-gray-400 smallText capitalize">
                  Admin
                </p> */}
              </div>
            </div>
            <div>
              <FiLogOut
                className="w-9 h-9 p-1.5 text-2xl text-accent"
                onClick={() => {
                  logout();
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="hidden xl:block h-28 bg-blue-700 rounded-br-2xl rounded-bl-2xl relative">
        <img
          className="w-full h-full object-cover rounded-br-2xl rounded-bl-2xl"
          src={require('../../Assets/img/odds/Graphic Side.jpg')}
        />
        <h1 className="highlightText text-white  absolute top-1/2 left-6 -translate-y-1/2 capitalize">
          Welcome, {profileData?.hospitalProfile?.hospitalName}
        </h1>
      </div>
      <main>
        {/* Dashboard sm screen start */}
        <div className="md:hidden p-4 mb-16 dashboardSmDevices">
          <Slider {...settings}>
            <>
              <BookingsCard
                imgUrl={require('../../Assets/img/odds/calender.png')}
                bookingTitle={'In-person Bookings'}
                bookingCount={inpersoncount}
                bookingPercentage={'4.8%'}
                bookingFromDate={'from last week'}
              />
              <div className="mt-2">
                <BookingsCard
                  imgUrl={require('../../Assets/img/odds/virtualbookings.png')}
                  bookingTitle={'Virtual Bookings'}
                  bookingCount={virtualcount}
                  bookingPercentage={'10.8%'}
                  bookingFromDate={'from last week'}
                />
              </div>
            </>
            <>
              <MonthlyCredits
                monthlydata={monthlydata}
                monthlylable={monthlylable}
                totalCreditCount={totalcreditused}
              />
            </>
            <>
              <ConsultationHistory
                consultationhistorylable={consultationhistorylable}
                consultationhistoryremote={consultationhistoryremote}
                consultationhistoryinperson={consultationhistoryinperson}
                consultationhistorysucc={consultationhistorysucc}
                consultationhistoryremotecount={consultationhistoryremotecount}
                consultationhistoryinpersoncount={
                  consultationhistoryinpersoncount
                }
                consultationhistorysucccount={consultationhistorysucccount}
              />
            </>
          </Slider>
          <section className="mt-14 mb-14">
            <div className="flex justify-between items-center mb-4">
              <p className="text-coalBlack subHeadingText capitalize">
                Request History
              </p>
              <Link to={'/consultation'}>
                <p className="capitalize smallText text-navLink">
                  see all
                </p>
              </Link>
            </div>
            <div className="space-y-3">{consultationBlock}</div>
          </section>
        </div>
        {/* Dashboard sm screen end */}
        {/* Dashboard large screen start */}
        <div className="mt-6 p-4 hidden md:block">
          <div className="grid grid-cols-12 gap-6  py-4 xl:px-8 xl:pb-0">
            <div className="col-span-12 xl:col-span-8 ">
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2  3xl:grid-cols-2 place-content-stretch ">
                <BookingsCard
                  imgUrl={require('../../Assets/img/odds/calender.png')}
                  bookingTitle={'In-person Bookings'}
                  bookingCount={inpersoncount}
                  bookingPercentage={'4.8%'}
                  bookingFromDate={'from last week'}
                />

                <BookingsCard
                  imgUrl={require('../../Assets/img/odds/virtualbookings.png')}
                  bookingTitle={'Virtual Bookings'}
                  bookingCount={virtualcount}
                  bookingPercentage={'10.8%'}
                  bookingFromDate={'from last week'}
                />

                <MonthlyCredits
                  monthlydata={monthlydata}
                  monthlylable={monthlylable}
                  totalCreditCount={totalcreditused}
                />

                <ConsultationHistory
                  consultationhistorylable={consultationhistorylable}
                  consultationhistoryremote={consultationhistoryremote}
                  consultationhistoryinperson={consultationhistoryinperson}
                  consultationhistorysucc={consultationhistorysucc}
                  consultationhistoryremotecount={
                    consultationhistoryremotecount
                  }
                  consultationhistoryinpersoncount={
                    consultationhistoryinpersoncount
                  }
                  consultationhistorysucccount={consultationhistorysucccount}
                />

                <div className="mt-8 sm:col-span-2 3xl:col-span-2 p-4 mb-6 lg:mb-0">
                  <div className="flex justify-between items-center mb-6">
                    <p className="capitalize text-coalBlack subHeadingText">
                      Request History
                    </p>
                    <Link to={'/consultation'}>
                      <span className="capitalize text-navLink smallText cursor-pointer">
                        see all
                      </span>
                    </Link>
                  </div>
                  <div className="space-y-3">{consultationBlock}</div>
                </div>
              </div>
            </div>
            <div className="col-span-4  hidden xl:block max-h-[500px] sticky top-24 ">
              <div className="grid grid-cols-2 gap-2 mb-4">
                <button
                  className="text-white bg-accent baseText flex items-center gap-2 px-4 py-2 rounded-[4px] whitespace-nowrap"
                  onClick={handelCreateRequestBlock}
                >
                  <span className="baseText">+</span>
                  New Request
                </button>
                <select
                  className="bg-accent text-white px-4 py-2 rounded-[4px]"
                  value={notificationFilter}
                  onChange={handleFilterChange}
                >
                  <option
                    value="today"
                    className="text-coalBlack bg-white baseText whitespace-nowrap"
                  >
                    Today
                  </option>
                  <option
                    value="weekly"
                    className="text-coalBlack bg-white baseText whitespace-nowrap"
                  >
                    This Week
                  </option>
                  <option
                    value="monthly"
                    className="text-coalBlack bg-white baseText whitespace-nowrap"
                  >
                    This Month
                  </option>
                </select>
              </div>
              <div className="p-4 pb-0 rounded-xl h-full relative bg-white">
                <SideMessageNotification
                  data={notification}
                  profileData={profileData}
                  currentdata={currentrequest}
                  setcurrentrequest={setcurrentrequest}
                  viewFunction={handelViewRequestBlock}
                  notificationfun={() => {
                    getNotification(profileData?.hospitalProfile?._id);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Dashboard large screen end */}
      </main>
      {/* create request for sm devices  */}
      <div
        className="fixed bottom-20 right-4 z-30 xl:hidden"
        onClick={handelCreateRequestBlock}
      >
        <button className="text-white bg-accent baseText !font-medium flex items-center gap-1 px-4 py-2 rounded-2xl whitespace-nowrap">
          <span className="text-xl">+</span>
          New request
        </button>
      </div>
      {/* create request block  */}
      {createRequestBlock && (
        <Createrequest
          closeFunction={handelCreateRequestBlock}
          editFormValue={null}
          profileData={profileData}
          onload={fetchData}
        />
      )}
      {editRequestBlock && (
        <Createrequest
          closeFunction={handelEditRequestBlock}
          editFormValue={currentrequest}
          profileData={profileData}
          onload={fetchData}
        />
      )}
      {ViewRequestBlock && (
        <ConsultationViewRequest
          currentdata={currentrequest}
          profileData={profileData}
          closeFunction={handelViewRequestBlock}
          onload={onload}
        />
      )}
    </AdminLayout>
  );
}

export default DashboardOdds;
