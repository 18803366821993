import * as yup from 'yup';
import { isValidWebsiteLink } from '../../apis';
import { isValidPhoneNumber } from 'react-phone-number-input';
// import { addDays } from 'date-fns';
const URL = /^(?:(?:https?|http|www)\:\/\/)?(?:[a-zA-Z0-9\-]+\.)+(?:(?:[a-zA-Z]{2,4})|(?:[a-zA-Z0-9\-]+))(?:\:[0-9]+)?(?:[a-zA-Z0-9\-\._\?\,\'\/\\\+&%\$#\=~])*$/;

//  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
// /((https?):\/\/)/;

export const WltSignInValidation = yup.object({
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('This field can\'t be empty'),
});

// sign-in, sign-up & forgot password validation start
export const SignupValidation = yup.object({
  // name: yup.string().required('This field can\'t be empty'),
  email: yup
    .string()
    .email('Invalid email')
    .required('Please enter a valid email'),
  password: yup
    .string()
    .min(5, 'Password with a minimum of five characters is required!')
    .max(50, 'Too Long!')
    .required('This field can\'t be empty'),
  terms: yup.bool().oneOf([true], 'You must accept the terms and conditions.'),
});
export const SignInValidation = yup.object({
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('This field can\'t be empty'),
  password: yup
    .string()
    // .min(5, 'Password with a minimum of five characters is required!')
    // .max(50, 'Too Long!')
    .required('This field can\'t be empty'),
});
export const ForgotPasswordEmailValidation = yup.object({
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('This field can\'t be empty'),
});
export const ForgotPasswordOTPValidation = yup.object({
  otp: yup.string().required('This field can\'t be empty'),
});
export const ResetPasswordValidation = yup.object({
  newpassword: yup
    .string()
    .min(5, 'Password with a minimum of five characters is required!')
    .max(50, 'Too Long!')
    .required('This field can\'t be empty'),
  confirmpassword: yup
    .string()
    .oneOf([yup.ref('newpassword'), null], 'Passwords must match') // Add this line
    .min(5, 'Password with a minimum of five characters is required!')
    .max(50, 'Too Long!')
    .required('This field can\'t be empty'),
});
// sign-in, sign-up & forgot password validation end

// hospital registration validation start
export const HospitalRegistrationStepOne = yup.object({
  hosName: yup.string().required('This field can\'t be empty'),
  speciality: yup.string().required('This field can\'t be empty'),
  address: yup.string().required('This field can\'t be empty'),
  latitude:yup.string().required('This field can\'t be empty'),
  longitude:yup.string().required('This field can\'t be empty'),
  // representativePhoneNumber: yup.string().test('Validator-custom-name', function (value) {
  //   if (value) {

  //     if (!isValidPhoneNumber(value)) {
  //       return this.createError({
  //         message: 'Invalid Phone Number',
  //       });
  //     }
  //     else {
  //       return true;
  //     }
  //   } else {
  //     return this.createError({
  //       message: 'This field can\'t be empty',
  //     });
  //   }
  // }),

  representativePhoneNumber: yup
    .string()
    .required('This field can\'t be Empty')
    .matches(/^(?!0)\d{10}$/, 'Invalid mobile number'),

  representativeEmail: yup
    .string()
    .email('Invalid email')
    .required('Please enter a valid email'),
  // RegistrationNumber: yup.string().required('This field can\'t be empty'),
  // capacity: yup.string().required('This field can\'t be empty'),
  // hospitalLicense: yup.mixed().required('This field can\'t be empty'),
});
export const HospitalRegistrationStepTwo = yup.object({
  RegistrationNumber: yup.string().required('This field can\'t be empty'),
  capacity: yup.string().required('This field can\'t be empty'),
  hospitalLicense: yup.mixed().required('This field can\'t be empty'),
  // profile:yup.mixed().required('This field can\'t be empty'),
  // cover:yup.mixed().required('This field can\'t be empty')
});
// hospital registration validation END
// purchase Credit start
export const purchasesCreditValidation = yup.object({
  creditItem: yup.string(),
});
// purchase Credit End
// consultation request form validation start
export const ConsultationRequest = yup.object({
  consultationType: yup.string().required('This field can\'t be empty'),
  specialization: yup.string().required('Select a specialization'),
  consultationPriority: yup.string().required('This field can\'t be empty'),
  broadcastType: yup.string().required('This field can\'t be empty'),
  doctorList: yup.array(),
});
export const ConsultationRequest2 = yup.object({
  requestedDate: yup
    .date()
    .min(new Date(new Date().setHours(0, 0, 0, 0)), 'Date must be today or in the future') // Ensure it's today or future
    .required('This field can\'t be empty'),
  ReferralDoctor: yup.string().required('This field can\'t be empty'),
  ReferralDoctorPhone: yup
    .string()
    .required('This field can\'t be Empty')
    .matches(/^(?!0)\d{10}$/, 'Invalid mobile number'),
  Age: yup.string().required('This field can\'t be empty'),
  // medicalTags: yup
  //   .array()
  //   .min(1, 'There should be atleast one medical tag to continue')
  //   .required('This field can\'t be empty'),
  gender: yup.string().required('A  gender selection is required'),
  // consultationPriority: yup.string().required('This field can\'t be empty'),

  RequestDescription: yup
    .string()
    .required('This field can\'t be empty')
    .max(500, 'Maximum 500 characters allowed'),
  // MedicalTag: yup.string().required('This field can\'t be empty'),
});
// consultation request form validation end

export const updateUserValidation = yup.object({
  name: yup.string().required('This field can\'t be empty'),
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('This field can\'t be empty'),
  image: yup.mixed(),
});

// edit user profile validation start
export const editUserProfile = yup.object({
  HospitalName: yup.string().required('This field can\'t be empty'),
  HospitalAddress: yup.string().required('This field can\'t be empty'),
  userEmail: yup
    .string()
    .email('Invalid email')
    .required('Please enter a valid email'),
  contactNumber: yup
    .string()
    .matches(/^\d{10}$/, 'Mobile number must be exactly 10 digits')
    .required('Mobile number is required'),
  latitude:yup.string().required('This field can\'t be empty'),
  longitude:yup.string().required('This field can\'t be empty'),
  // dateOfBirth: yup.string().required('This field can\'t be empty'),
  // location: yup.string().required('This field can\'t be empty'),
});
export const HelpcenterValidation = yup.object({
  name: yup.string().required('This field can\'t be empty'),
  registeredemail: yup
    .string()
    .email('Invalid email')
    .required('Please enter a valid email'),
  registeredmobile: yup
    .string()
    .matches(/^\d{10}$/, 'Mobile number must be exactly 10 digits')
    .required('Mobile number is required'),
    description:yup.string().required('This field can\'t be empty'),
    subject:yup.string().required('This field can\'t be empty'),
});

export const deleteAcctPassword = yup.object({
  password: yup
    .string()
    .min(5, 'Password with a minimum of five characters is required!')
    .max(50, 'Too Long!')
    .required('This field can\'t be empty'),
});
// edit user profile validation end
// subscription validation start
export const subscription = yup.object({
  subscription: yup
    .string()
    .oneOf(
      ['platinum', 'gold', 'silver'],
      'Please Select A Subscription Plan To Continue'
    )
    .required('Please Select A Subscription Plan To Continue'),
});




